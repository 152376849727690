<template>
  <FormCard
    :title="color.name ? color.name : 'Nova cor'"
    :status="color.status"
    @cancel="$router.push({ name: 'colors' })"
    @save="validate"
  >
    <v-form
      class="px-5 mt-5"
      ref="form"
    >
      <v-row
        dense
        no-gutters
      >
        <v-col
          class="d-flex"
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="color.name"
            class="mr-3"
            label="Nome"
            placeholder="Nome da cor"
            outlined
            rounded
            dense
            hide-details
          />
          <v-btn
            @click="onButtonClick"
            class="primary"
            rounded
            dense
          >
            Adicionar imagem
            <v-icon
              class="ml-2"
              small
              >fas fa-image</v-icon
            >
          </v-btn>
          <input
            ref="uploader"
            id="image_color"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          rows="12"
          md="2"
        >
          <v-avatar
            class="u-cursor-pointer"
            width="120px"
            height="170px"
            rounded
            color="grey lighten-4 elevation-2"
          >
            <img
              v-if="color.image_color != null || colorImage != null"
              :src="
                colorImage != null
                  ? colorImage
                  : $store.state.imagePath + color.image_color
              "
              class="c-profile-picture"
              alt="foto de perfil"
            />
            <v-icon
              v-else
              color="primary"
            >
              fas fa-image</v-icon
            >
          </v-avatar>
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  props: {
    valid: Boolean,
    color: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
  },
  data: () => ({
    colorImage: null,
    profile: null,
  }),
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.color.image_color = e.target.files[0]
      this.colorImage = URL.createObjectURL(e.target.files[0])
    },
    clearFileField() {
      this.color.image_color = null
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
  mounted() {
    if (this.color.image_color) {
      this.colorImage = this.color.image_color
    }
  },
}
</script>

<style>
.c-profile-picture {
  object-fit: cover;
}
.u-cursor-pointer {
  cursor: pointer;
}
</style>